import React, { useState, useEffect } from "react";
import "./App.css";
import Quiz from "./Components/Quiz";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import desktopImage from "./Assets/desktop.jpg";
import mobileImage from "./Assets/desktop.jpg";
import mediumImage from "./Assets/desktop.jpg";
import Result from "./Components/Results";
import Form from "./Components/Form";
// import { FaRocket } from 'react-icons/fa';
function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl =
    windowWidth >= 650
      ? desktopImage
      : windowWidth >= 450
      ? mediumImage
      : mobileImage;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    // style={backgroundColor : '#defffa'}>
    // { backgroundImage: `url(${imageUrl})` }
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* quiz page  */}
          <Route
            path="/"
            element={
              <>
                <Quiz />{" "}
              </>
            }
          />
          <Route
            path="/form"
            element={
              <>
                <div className="flex w-auto justify-center items-center h-screen">
                  <Form />
                </div>
              </>
            }
          />
          <Route
            path="/result"
            element={
              <>
                <Result />{" "}
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
