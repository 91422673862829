import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaAngleUp, FaAngleDown, FaCheck } from "react-icons/fa";
import ConvertKitForm from "convertkit-react";
import Form from "./Form";
export default function () {
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([
    //Alpha
    {
      id: 1,
      question: "You are innovative, daring, provocative and inspirational",
      category: "Creator",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 2,
      question: "You achieve everything what you put your mind into",
      category: "Creator",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 3,
      question: "You are creating meaningful connections between people",
      category: "Lover",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 4,
      question: "You are empathetic or soothing",
      category: "Lover",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 5,
      question: "You are a caring and warm person",
      category: "Caregiver",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 6,
      question:
        "You think that we should take care of our neighbors as we take care of us",
      category: "Caregiver",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 7,
      question: "You are honest",
      category: "Hero",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 8,
      question: "You are brave",
      category: "Hero",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 9,
      question: "You are a fearless person",
      category: "Explorer",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 10,
      question: "You are a go-getter",
      category: "Explorer",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 11,
      question: "You are a friendly and humble person",
      category: "Common man",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 12,
      question: "You treat others with honesty and friendliness",
      category: "Common man",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 13,
      question: "You like to spend time learning",
      category: "Sage",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 14,
      question: "You meal prep or schedule week on weekends",
      category: "Sage",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 15,
      question: "You are successful in work and in life",
      category: "Ruler",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 16,
      question: "You are commanding, articulate and refined",
      category: "Ruler",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 17,
      question: "Simplicity is elegant",
      category: "Innocent",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 18,
      question: "You are optimistic and humble",
      category: "Innocent",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 19,
      question: "You like to bring joy or fun into whatever you do",
      category: "Magician",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 20,
      question: "You like to impress others",
      category: "Magician",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 21,
      question: "You are playful",
      category: "Jester",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 22,
      question: "You are fun or optimistic",
      category: "Jester",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 23,
      question: "You are rebellious",
      category: "Outlaw",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
    {
      id: 24,
      question: "You like to be on your own terms",
      category: "Outlaw",
      options: [
        {
          id: "A",
          op: "Strongly disagree",
          isSelected: false,
          score: 0,
          type: "Physical",
        },
        {
          id: "B",
          op: "Disagree",
          isSelected: false,
          score: 0.25,
          type: "Spiritual",
        },
        {
          id: "C",
          op: "Neutral",
          isSelected: false,
          score: 0.5,
          type: "Mental",
        },
        {
          id: "D",
          op: "Agree",
          isSelected: false,
          score: 0.75,
          type: "Mental",
        },
        {
          id: "E",
          op: "Strongly agree",
          isSelected: false,
          score: 1,
          type: "Mental",
        },
      ],
    },
  ]);
  const [render, setRender] = useState(0);
  const [count, setCount] = useState(0);
  const [idx, setIdx] = useState(0);
  const [showContent, setShowContent] = useState("");
  const MY_FORM_ID = 4808621;
  const config = {
    formId: MY_FORM_ID,

    emailPlaceholder: "Enter an email address",
    submitText: "Sign up",
  };
  useEffect(() => {
    setShowContent("Start");
  }, []);
  function nextHandler(data) {
    setShowError(true);
    for (let i in questions[data].options) {
      if (
        questions[data].options[i].isSelected &&
        questions.length - 1 > data
      ) {
        setCount(count + 1);
        setRender(render + 1);
        setShowError(false);
      }
    }
    if (questions.length - 1 == data) {
      setShowError(false);
      setIdx(data);
    }
  }
  function previousHandler(data) {
    setShowError(false);
    if (count > 0) {
      setCount(count - 1);
      setRender(render + 1);
    }
  }
  function submitData() {
    let keys = [];
    let results = {};
    for (let i in questions) {
      keys.push(questions[i].category);
    }
    let uniqueKeys = [...new Set(keys)];
    for (let j in uniqueKeys) {
      results[uniqueKeys[j]] = 0;
    }

    for (let i in questions) {
      for (let j in questions[i].options) {
        for (let k in uniqueKeys) {
          if (
            questions[i].options[j].isSelected &&
            questions[i].category == uniqueKeys[k]
          ) {
            results[uniqueKeys[k]] += questions[i].options[j].score;
          }
        }
      }
    }

    let sortable = [];
    for (var key in results) {
      sortable.push([key, results[key]]);
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    console.log(sortable, "sortable", results);
    let answer = sortable[0][0];
    let value = sortable[0][1];
    localStorage.setItem("Result", answer);
    localStorage.setItem("Score", value);
    // setShowContent("Answer");
    // setShowContent("Form");
    navigate("/form");
  }
  function start() {
    setShowContent("Questions");
    // setShowContent("Form");
  }
  const handleClick = (op, ques, count) => {
    setShowError(false);
    for (let i in questions) {
      if (ques == questions[i].question) {
        for (let j in questions[i].options) {
          if (op.id == questions[i].options[j].id) {
            questions[i].options[j].isSelected = true;
          } else {
            questions[i].options[j].isSelected = false;
          }
        }
      }
      setRender(render + 1);
    }
  };

  return (
    <div
      className={
        showContent == "Start"
          ? "text-center bodyDiv start"
          : "text-center bodyDiv"
      }
    >
      {showContent == "Start" && (
        <div className="lg:text-xl lg:ml-20 md:text-xl md:ml-14 sm:ml-14 ml-2">
          <p className="md:text-5xl">WHAT IS YOUR ARCHETYPE ?</p>
          <p className="">Select how well you agree to the below statements</p>
          <br />
          <button
            onClick={() => start()}
            className="text-white custom-btn p-2 px-5 my-1 w-4/12  font-semibold"
          >
            START
          </button>
        </div>
      )}
      {showContent == "Questions" && (
        <div>
          <div className="flex flex-col items-baseline">
            {/* <div> */}
            <div
              key={render}
              className=" lg:text-xl lg:ml-20 md:text-xl md:ml-14 sm:ml-14 questionBody flex flex-col"
            >
              <div className="text-color font-bold ml-4 mb-8 flex lg:text-xl">
                {`${questions[count].id}) ${questions[count].question}`}{" "}
              </div>
              {questions[count].options?.map((options, index) => {
                return (
                  <div
                    key={index}
                    onClick={() =>
                      handleClick(options, questions[count].question, count)
                    }
                    className={
                      options.isSelected
                        ? "optionBoxselect flex ml-4  align-between sm:w-52 md:w-72 lg:w-72 cursor-pointer font-medium"
                        : "optionBox flex ml-4  align-between sm:w-52 md:w-72 lg:w-72 cursor-pointer font-medium"
                    }
                  >
                    <span>
                      <span className="optionIndex px-1 mb-2">{`${options.id}`}</span>
                      <span className="px-5 lg:text-xl section-text ">{`${options.op}`}</span>
                    </span>
                    <span className="color-fff section-text">
                      {options.isSelected ? <FaCheck /> : ""}
                    </span>
                  </div>
                );
              })}
              <div className="flex">
                {questions.length - 1 !== idx ? (
                  <div
                    onClick={() => nextHandler(count)}
                    className="my-1 p-2 px-4 cursor-pointer okayButton"
                  >
                    {"Ok"}
                    <FaCheck />
                  </div>
                ) : (
                  <div
                    onClick={() => submitData(count)}
                    className="p-2 cursor-pointer submitButton"
                  >
                    {"Submit"}
                  </div>
                )}
              </div>
            </div>
            <div>
              {showError ? (
                <p className="text-red-500">'Please select the answer'</p>
              ) : (
                ""
              )}
            </div>
            {/* </div> */}
          </div>
          <div className="flex flex-row w-full justify-end items-end bottomContent ">
            <div
              onClick={() => nextHandler(count)}
              className="m-1 p-2 px-4 cursor-pointer button text-white"
            >
              <FaAngleDown />
            </div>
            <div
              onClick={() => previousHandler(count)}
              className="m-1 p-2 px-4 cursor-pointer button text-white"
            >
              <FaAngleUp />
            </div>
          </div>
        </div>
      )}
      {showContent == "Form" && (
        <div>
          {" "}
          <Form />
          {/* <ConvertKitForm className="text-white" {...config} /> */}
        </div>
      )}
      {showContent == "Answer" && (
        <div className="answerContents sm:text-3xl md:text-4xl">
          {" "}
          <span>{"Here is your result mate!..."}</span>
          <span className="mt-4">
            {" "}
            {`Your are a "`}
            <span className="font-bold text-amber-500">
              {localStorage.Result}
            </span>{" "}
            {`"`}
          </span>{" "}
        </div>
      )}
    </div>
  );
}
