import React from "react";
import { useNavigate } from "react-router-dom";

function Results() {
  const navigate = useNavigate();
  // Function will execute on click of button
  const onButtonClick = () => {
    let skipped = localStorage.getItem("skipped");
    // console.log(skipped, typeof skipped, "iusskipped");
    if (skipped === "true") {
      navigate("/form");
    }
    // else {
    //   console.log("download gonna start anytime");
    // }
    else {
      // using Java Script method to get PDF file
      let result = localStorage.getItem("Result");
      let pdf = "";
      if (result == "Common man") {
        pdf = "The_Common-Man.pdf";
      } else pdf = `The_${result}.pdf`;
      // let name_of_pdf = '';
      // switch(pdf) {
      //     case 'Creator':
      //         return name_of_pdf = 'The_Creator.pdf'
      //       // code block
      //       break;
      //     case y:
      //       // code block
      //       break;
      //     default:
      //       // code block
      //   }
      // fetch("The_Caregiver.pdf").then((response) => {
      fetch(pdf).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = pdf;
          alink.click();
        });
      });
    }
  };
  //   let result = localStorage.getItem("Result");
  return (
    <div className=" text-center flex flex-col items-start justify-center w-100 lg:text-xl lg:ml-20 md:text-xl md:ml-14 sm:ml-14 ml-3">
      {" "}
      <div className="flex flex-col   text-start lg:w-6/12">
        {" "}
        <span>Here’s your results!…</span>
        <span className="mt-4 text-small">
          <span className="text-3xl font-medium">
            {" "}
            You are a "
            <span className="font-bold result-text">
              {localStorage.Result ? localStorage.Result : "Common man"}
            </span>
            "
          </span>
          {localStorage.Result == "Caregiver" && (
            <p>
              The Caregiver Brand Archetype is a personality of excessive
              empathy who wants to protect and serve others. They're selfless
              and often put others before themselves. They fear ingratitude and
              selfishness, and their strategy is to do good. They have the
              talents of compassion and generosity, and their motto is "Love
              your neighbor as yourself." Caregivers are protectors of those in
              need and use their capabilities to serve and defend. They're all
              about vital, joyful services and fighting for those who aren't
              thankful.
            </p>
          )}
          {localStorage.Result == "Innocent" && (
            <p className="text-start">
              People regularly amplify recognizable styles of human behavior in
              their testimonies. For people concerned about 'coping with the
              meaning', archetypes become an effective device that lets brands
              inform compelling testimonies. In turn, testimonies are how we
              make the arena experience around us. Understanding brand
              archetypes is an effective device for helping you to pass under
              the floor and meet invisible and rising needs. They help groups
              build great brands and create amazing new artworks because
              archetypes help them communicate with their audiences on a deeper
              mental level. Innocent's existence shouldn't be complicated. The
              photo of innocence conveys the message that you are loose to be
              yourself and to stay out of your desires properly now.
            </p>
          )}
          {localStorage.Result == "Common man" && (
            <p>
              The Common-Man seeks to be ordinary by using society and selling
              equality. They're stimulated with the aid of using an experience
              of belonging. The Common-Man is established as very relatable and
              wholesome, which lets them foster genuine relationships with
              others. They're often hardworking, down-to-Earth, those who yearn
              to have authentic relationships and genuinely need to be original
              with who they are. Every day people wish to fit in and be a part
              of a group. At the same time, this standard method is a surface
              stage embracing all people. It additionally manifests in becoming
              a member of cliques, social clubs, and memberships to be around
              like-minded peers. The Common-Man Brand Archetype is without
              difficulty visible in mom-and-pop stores, nearby diners, and
              network occasions that have a down-domestic culture, proper and
              caring.
            </p>
          )}
          {localStorage.Result == "Creator" && (
            <p>
              The Creator archetype pushes the aid of using their preference to
              produce. They love the system of making as much as they love the
              outcome. This archetype is modern and imaginative. They're
              non-conformists, which makes sense — creators want to deliver
              brand-new thoughts to clients or take something current and
              re-consider it into something new. The Creator's motto is "if I
              can imagine it, I can create it." Creators don't fear whether
              something is possible; instead, they fear slow speed. They need to
              make significant merchandise at masses of cost for their dreamy
              clients. The Creator's clients are pleasing, paying top-class for
              merchandise and services. The Creator may be very authentic — this
              facilitates joining them with their audience. They are an
              excellent suit for marketing, design, or tech-targeted brands.
            </p>
          )}
          {localStorage.Result == "Explorer" && (
            <p>
              The Explorer Archetype runs through all of society in Western
              culture, talking about the want for freedom, adventure, and
              independence. Archetypes create the general styles of human
              behavior. An archetype is an authentic version or sample of the
              collective unconscious. We integrate them with our character minds
              as the default. Archetypes support writers in creating testimonies
              that interest readers. It's simple for readers to connect to those
              works because they’re not unusual to place threads of human
              existence. Archetypes support readers in peering at themselves and
              discovering one of the extra characters in a story.
            </p>
          )}
          {localStorage.Result == "Hero" && (
            <p>
              The brave acts of the Hero Archetype are well-known. They stand
              for proving their healthy worth in opposition to evil and growing
              to fulfill any challenge. They're relatively inspired and could do
              whatever to reap their deepest desires. For the Hero, in which
              there's a will, there's a way. The Hero seeks out complex
              challenges. They exude self-assurance in their moves which attract
              their dreamy customers and are most fulfilled after triumphing
              over a challenge. They decided to leave a mark on themselves.
              Heroes are first-rate and efficient. They don't have time for
              matters and don't use a substance that doesn't support them in
              triumph. Heroes volunteer for jobs nobody else will take. In
              business, the Hero archetype is an image of braveness and a supply
              of inspiration. Hero brands are typically visible in sports
              activities and equipment, leading to their formidable and
              confident personality. These brands put on a superhero cape. Their
              challenge is to make the arena a higher place, representing
              alternate through sacrifice.
            </p>
          )}
          {localStorage.Result == "Jester" && (
            <p>
              An Archetype Jester tends to present the impact that they stay at
              the wild aspect and use outrageous imagery and humor. They're
              carefree and exceptional; however, they may be ruthless and
              irresponsible. Archetypes are personas, patterns, and subject
              matter that have a routine illustration in cultures. Their roots
              are in the collective subconscious of humanity, which refers to
              the reports of numerous races and cultures. Readers analyze the
              morals of testimonies through archetypes. These are recreated and
              applied in works of artwork and literature. They supply power and
              weaknesses to characters which allows readers to recognize the
              morals of testimonies.
            </p>
          )}
          {localStorage.Result == "Lover" && (
            <p>
              The Lover Brand Archetype is a character that empowers your
              enterprise to create a recognizable brand so that your perfect
              customers will at once relate to it. Here are 12 brand archetypes
              made by layout specialists who understand what it takes to deliver
              your brand. You probably know the word "archetype" in a preferred
              sense. Carl Jung coined the period in psychology, placing forth
              the concept that four elementary classes encapsulate the most
              character traits. By increasing that list, designers can get even
              more specific, introducing your brand in a manner that feels
              authentic to who you're. That'll lead your target market to
              connect to you as they get to
            </p>
          )}
          {localStorage.Result == "Magician" && (
            <p>
              Archetypes are literary gadgets that arise in literature and art.
              They depict human nature. An archetype is a grouping of traits,
              such as ideas, behaviors, emotions, or motifs, that are used in
              literature. The Magician is an archetype related to mystery,
              alchemy, and transformation. The Magician builds different
              attributes, creating a feeling of connection between the reader
              and the writer. The Magician archetype gets its description from
              offering mystery knowledge. It often appears in fantasy, fiction,
              art, and movies. They have sturdy ideals of their capabilities and
              dreams — Magicians discover answers to troubles with technology or
              supernatural methods relying on the story. They're known as
              visionaries, healers, shamans, and remedy men.
            </p>
          )}
          {localStorage.Result == "Outlaw" && (
            <p>
              Outlaws can have good or bad stories. Mostly, they try to help
              those around them. With a positive attitude, they act as fighters,
              revolutionaries, or dreamers who fight against unfairness. If they
              have an advantage, too, that's even better. In short, Outlaws are
              there to shake up the order of the world. On the other hand, in
              bad stories, they act as rebels or criminals where their goals
              come first. They use unkind ways to get what they want. It's not
              unusual for a good Outlaw to refuse to do bad things to reach
              their goals.
            </p>
          )}
          {localStorage.Result == "Ruler" && (
            <p>
              Ruler Brands are folks who offer shape, yearning order, and
              control. Rulers are the leader or the boss. Rulers are seen as
              proud and ambitious –herbal leaders seeking to create order.
              Meaningful goals often characterize order. Achievement and
              prosperity for each of themselves and their clients. Above all,
              they prefer authority. The shape is the handiest they can bring.
              This persona boils down to the message that electricity drives
              achievement. Implementing this message by demonstrable management
              is a critical component of the Ruler Archetype. The beliefs of
              making and maintaining order inspire them. It is constructing a
              global that they see as optimal. Rulers worry about chaos and
              display weakness. Therefore, they must usually show self-belief
              and, most significantly, have a plan. However, they also want to
              be aware of being boisterous or controlling. In other words, they
              want to apprehend the distinction between management and
              bossiness.
            </p>
          )}
          {localStorage.Result == "Sage" && (
            <p>
              Ruler Brands are folks who offer shape, yearning order, and
              control. Rulers are the leader or the boss. Rulers are seen as
              proud and ambitious –herbal leaders seeking to create order.
              Meaningful goals often characterize order. Achievement and
              prosperity for each of themselves and their clients. Above all,
              they prefer authority. The shape is the handiest they can bring.
              This persona boils down to the message that electricity drives
              achievement. Implementing this message by demonstrable management
              is a critical component of the Ruler Archetype. The beliefs of
              making and maintaining order inspire them. It is constructing a
              global that they see as optimal. Rulers worry about chaos and
              display weakness. Therefore, they must usually show self-belief
              and, most significantly, have a plan. However, they also want to
              be aware of being boisterous or controlling. In other words, they
              want to apprehend the distinction between management and
              bossiness.
            </p>
          )}
        </span>{" "}
      </div>
      <br />
      Download a full PDF and learn more about The {localStorage.Result}{" "}
      archetype!
      <button
        className="custom-btn p-2 px-4 my-1 font-medium"
        onClick={onButtonClick}
      >
        Download PDF
      </button>
    </div>
  );
}

export default Results;
