import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Form() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("skipped", false);
  }, []);
  const skipHandler = () => {
    console.log("this mf just skipped");
    localStorage.setItem("skipped", true);
    navigate("/result");
  };
  return (
    <div className="lg:text-xl lg:ml-20 md:text-xl md:ml-14 sm:ml-14">
      <p className=" font-medium">
        Where can we send your in-depth archetype analysis?
      </p>
      <script src="https://f.convertkit.com/ckjs/ck.5.js" />
      <form
        action="https://app.convertkit.com/forms/4808621/subscriptions"
        className="seva-form formkit-form"
        method="post"
        data-sv-form="4808621"
        data-uid="8e03a2758d"
        data-format="inline"
        data-version="5"
        data-options='{"settings":{"after_subscribe":{"action":"redirect","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":"https://kelooj.netlify.app/result"},"analytics":{"google":null,"fathom":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
        min-width="400 500 600 700 800"
      >
        <div data-style="clean">
          <ul
            className="formkit-alert formkit-alert-error"
            data-element="errors"
            data-group="alert"
          ></ul>
          <div
            data-element="fields"
            data-stacked="true"
            className="seva-fields formkit-fields"
          >
            <div className="formkit-field">
              <input
                className="w-full p-2 m-2"
                aria-label="First Name"
                name="fields[first_name]"
                required=""
                placeholder="First Name"
                type="text"
              />
            </div>
            <div className="formkit-field">
              <input
                className="w-full p-2 m-2"
                aria-label="Last Name"
                name="fields[last_name]"
                required=""
                placeholder="Last Name"
                type="text"
              />
            </div>
            <div className="formkit-field">
              <input
                className="w-full p-2 m-2"
                name="email_address"
                aria-label="Email Address"
                placeholder="Email Address"
                required=""
                type="email"
              />
            </div>
            <button
              data-element="submit "
              className="formkit-submit custom-btn p-2 px-4 w-full m-2"
            >
              <div className="formkit-spinner">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span className="">Subscribe</span>
            </button>
          </div>
          <div className="formkit-powered-by-convertkit-container"></div>
        </div>
      </form>
      {/* <Link to={"/result"} className=" ">
        Skip for now !
      </Link> */}
      <div className="cursor-pointer text-center mt-2" onClick={skipHandler}>
        Skip for now !
      </div>
    </div>
  );
}

export default Form;
